import React from "react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Home } from "./pages/Home";
import { Logout } from "./pages/Logout";
import { Login } from "./pages/Login";
import { StickyNavbar } from "./components/StickyNavbar";
import { StickyFooter } from "./components/StickyFooter";
import { AuthProvider } from "./components/AuthContext";
import { RefreshToken } from "./components/RefreshToken";
import { SessionDialog } from "./components/SessionDialog";
import { AdminPage } from "./pages/Admin";
import { CollapsableSidebar } from "./components/CollapsableSidebar";
import { OktaAutoLogin } from "./components/OktaAutologin";
import "./App.css";

const App = ({ oktaAuth }) => {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
    }
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <AuthProvider>
        <OktaAutoLogin />
        <RefreshToken />
        <StickyNavbar />
        <div className="flex">
          <CollapsableSidebar />
          <div className="w-full p-4">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/login/callback" element={<LoginCallback />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/" element={<Home />} />
              <Route path="/admin" element={<AdminPage />} />
            </Routes>
            <SessionDialog />
            <StickyFooter />
          </div>
        </div>
      </AuthProvider>
    </Security>
  );
};

export default App;
