import { Navbar, Typography } from "@material-tailwind/react";
// Logo resources
import { kyndrylLogo } from "../assets/kyndrylLogo";
import { UserPopupState } from "./UserPopupState";

export const StickyNavbar = () => {
  return (
    <div>
    <Navbar className="ml-0 pt-2 sticky flex justify-between pl-5 pr-5">
      <img
        className="w-60 object-cover"
        alt="Kyndryl Logo"
        src={kyndrylLogo}
      />
      <Typography className="text-3xl text-black mr-4 cursor-pointer py-1.5 font-medium">
        USCP Application Repository
      </Typography>
      <UserPopupState/>
    </Navbar>
    <hr className="h-1 my-4 mx-auto rounded bg-gray-100 dark:bg-gray-300"></hr>
    </div>
  );
};
