import { Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";

export const LogoutButton = () => {
  return (
    <div className="flex p-1">
      <ThemeProvider theme={theme}>
        <Button
          variant="outlined"
          sx={hoverStyles}
          onClick={() => window.location.replace('/logout')}
        >
          Sign Out
        </Button>
      </ThemeProvider>
    </div>
  );
};
