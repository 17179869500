
export const kyndrylLogo =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAY4AAAB+CAMAAAAEPwbjAAAAk1BMVE \
X/////Ry7/RCr/yML/6uf/u7T/QSX/PR//PBr/gHL/4d7/WEH/ORf/pZr/0c3/cmH/NRD/Y03/mo \
7/7+3/+vn/PyL/MQD/t6//9fT/joH/zMf/TDL/5eL/19P/lYn/3tv/h3r/wbr/n5T/s6v/Ujr/rK \
P/fG3/bFn/XEb/Y07/opj/bVv/fm7/hXb/jH7/kYb/IAA4ycz4AAAPX0lEQVR4nO1di3aquhZFVK \
BKK1WgVOvWtr5q2+3+/6+74IM1A3kpOZVrmWeM07ERQ8jMWlmvRKuVw+tYN46Bc3pXv32tPvSCUx \
/sPufjX0WH3dBRIzTSUSs00lErNNJRKzTSUSs00lErNNJRKzTSUSs0dNQKDR21QrN21AqNdNQKDR \
21QkNHrdDQUSs0dNQKDR21QkNHrdDQUSs0bmCt0EhHrdDQUSs0dNQKzdpRKzTSUSs00lErNNJRKz \
TSUSs00nEROt3d2vaDwF0Mht2X8747e9qtJ14Q+PbjfPkQMp+dLR3T5WqRtTVZLUP13VowQcc94G \
2cXelu6YpePzpv1MTxSdTI25RufO/HXnQYONuJvMAZajMy/esF6Xf3X7Ztx/XjdRc+FkrHS9637T \
K/mGzdwHUObTnxyLJG8AY9vQ6952+4fTpcMUDHKnBzBJ/7S904v/JvpNWzLTXil9qN81Fb+p7dYm \
BH8XoqaJRBdxE4rQJs39/mNwil4yF/G39zunYfu9COn73kxMtH4Y/WOyc0SvH74VJ1OoY+3eQcu5 \
vQNe9Jq2ubfCyi5+Olu3z0vCMdnT48C54afyWq9qcL3+Z9t+VGp7EXSsdD/lT78XBlVOjIno4/UX \
7bQuudX322AcsAHd2A7rFbp2GhV3MGOj17oVaCh+O1Eh0PMX9EUwqjB3HbGZ6FX23Z8ZF/sXQU6e \
h5BTnbj+YU3mGm89L0gs76eKkqHVNkw8vVeJeIj3WWuSWJflTu7YGOcSwa0WxMu+LG08nsir+aDu \
ZhxmhLR6ckaIfJvcgvu9tiFzgIqVPeUVdVpaMTQc8C0uEjkMNXja6t82ac+elagY6ehI0UEj5e3N \
KiwcL7ym7TlY5wUpK0Ax27iLlPhTGM0Wlwq9ER9pENHHcY3zt1z5C98eligY7yILAIRPrqpbj6c7 \
6bWUy60jEvi9qBjgfQVhrm3jOx93G6Vo2OQUQ3BIxJ++TRB+qewd1urttYOv6cBsFObVTHLo+wHf \
FtuCTi3LtvAqdST1s6phwpPdABYuMuuX1hQM/38rsr0bED+8J/Zj7iznchYOX/zi8ydHTiw5AH0e \
RxMBh89Fu+X1BB0Yrb9qZwm+0G9uJjMPh8XKR27pGrzODQlI6P0yhmnEZRlE2No2H0l+b7mtcVBr \
Dq5rqqEh3vsIy7RQPqg7TVc+mbBSQkZDASDB2ZXrb9xfDhJALhy+u3Q0KVIebxvmPvifz1++xk/y \
Uvr8+TA6vpuqcnHbPjvPC9yeNq/rx7vlv3naNzNQZtpXS3hryVpgId8PDU4SjaT++gf1Q9AwPco3 \
YYOtJnuYti7CJZRjj1SQUTWNVi+39LSv1hENh7C0JPOp7386K1G8N4JNNDr8MW6R+luwV2GKn5y+ \
nAFdKelPywDsiiymsGA3zFu+q9plYI19VNBjj5Oav5AhcO95EbvZhuvHQctej4sJxsXoiMxbm+u9 \
XjrvsX05GApWO7nNd8JD97KO9ZSE9BqxjpGEbBO//LzxgUKBlx7+g9+39FPfiTsj1b6dCRzjJJCK \
RNjwsUgYL73D6zN3T1YjrWoCa4NuYyn7fpzJMCtJ4PLwF0tNfBm+jbK7Du/OIYOCAcviSSdO+73Z \
XG2rFue6J5kSGkx6ncrQ+uz3gpHd+gJAKuB/aiHTMgAxx1FdARLV3OsnBE0oIxKKwuTyAckdT/2Q \
VznaX841k8L9g+K9ytDq1pODoX0nEPy3ggiKH3eWsVD6TfPSSWXq0VyQgFhRQVjLg+Lm/SPlibSM \
gq0ZGq5Y3g+weAtnKlwSGydJhxv4yOV2DDE2lkikNxm84xFZiHQIfUjAd7phCcwIBaoLAn0E4U0y \
H2/Q9Ak13qbn3SyoridhEdM+h6JDQh0HaQpRKHOW2sOQJ0yBXxDsYgFHygDixvtKRDFToneyASGg \
4pEhgbnCeX0DGC8ELZ4SAs9GIGfYGxDnTIzRSIxbGxIuinOuQNQWUxHQq1iy6UbUtu65KuYgi+hA \
4IO9gtife5JW0lXomZJZ9pjOhQzMmEzApGQ8xgVqtDrCDMYjpUKs9KXK17QYgYe+8COlb0RNuTZY \
VnWjEDMMA/mQ/ueIEsLki+GK0G810jJ5nQu4rp8JSxD3ImJe5WKNBVF9Ax1F/YJnyTiQUZ4B5r0R \
MdqmwODYGLTaxA22lk7DdqQ7flKFuhxJtEpskAKxh8Z9PxhNaKYs69CRZpREe44N/xB5mDb7oTFy \
mwcnWS158afoe6nRFoK6HqoGBKtGM+OJeOB2RDlYKc8d1tBmCAFxaYcumCCGRB4VLboSWl8NJ86M \
Ss5F7HHp8a7hZYDax+OZOOjktTzpsru+aIJhz0nj+zrXPoGHLpAK9D1cAe+qULUlAqTUgezeli/O \
g8OkIIkEafnPsLoHESxQwgTVV0TqrS0YWEpE4l1hmFPTKM6LGxwN0iaXYKzsl5dKzBr+prVIjgBO \
Xf8YrBaxZV6bgHLa5Te2dIOqBMQORuURih6LqfRceciLdtrepCUJL8mAFZPyVNW5UOKkNrxTp9NS \
QduBryYzuwpNqFOX0OHUtcxvXKUEksi9G9AyDGUyq+qEoHhiB1+mpKOjpQY8ads2BwFh0qfTpGWH \
YWqMsRDm9CDHJN9rbEaKlKx0DXqy/dX40O9KS4HV+IDRxtOpxXdP/0Cm9TQEEJbzklA9wt5RGq0k \
E6XMc+NScdEA1weMUtEBVyiuuvNh0tKjGPoq0VnqDo2l9pzADSsmXtV5WOD1HcXQBj0vEC1iJnfI \
Ctr+Jn+tIB2xf8KI6DeI9/qjSCKBxweFHZ0w3SIYlgEoxJB4ZbOJmBD8mnZ6wddHUIFtZEIR/gmJ \
dj3GT8cGTnx5WVKekAE5sT/BzREhyVQhWXZQMh6enO5V2biy1ZXNQ4+YjKSznRobeUG5MOjNWXPi \
SvnRPIu4yOHpq88o10YDuVHkAGOG/ADBq6ysK7DOakAyP+JRMUHlPWZBeWLiyxREPuEJJs+kXPgg \
zwiFN08+NuoDHpgMRbyd1KaDzcclj10sIeKLNSpKFhVItxclryeEGlHw+SmJMO8LuLHij5Cw4n6H \
cpHR2ss5JWz7eFUSkywLlVNz8eQjQnHbgoFh5Nb+Vz3uriKkQmDSXbXBKCtmInKRng3HyEyQC7jt \
tqUDpQDRdMRugUR2QvL5mGYky5IbkSDStZotwkb1U6INLND5gVYFI6ZiLBBz+MF1+8nI4E6l89WV \
E0aEtmlRlBEobnvFSlAzqvKkHcY22QDigTYMMNVABbWkkzVNjf0UZ1JalhCAW2BBng/AqxynRgoZ \
bGER4apQv6dAi6BBkH7u6+KrufYM8i7SjnQGBp02V+fLgyHbjjR71XL9GoQtSngxYuRpPDZW7gpg \
oduKPXlRRsk4mDKVooVismYQ6oTAc4qxp+OdZZVKcDtBWmaKmnHneCVNqqyZQki4cMPB+IKMOSwq \
9qq0wH7n1WVIRZjJtigo4dd5GgHvlc9VltIzPEEmVH+lC1CMQM7kR2ZfmGS+kYgjZVbmVdGFVWzB \
6d/OKMr8IIFU9dgLJvR/zCsGjnFmcIbj1/3alOx0w/tIbbRY3QAfXalKKl4IlgMatIRw9fQljlRT \
FlqisiA1xU81OdDtif2LJLmTcG4URnu805dNAmc+o+hBb5nnPVI2Iwligu2l+XYwbUW9HmDQN04J \
TnhYgIX/5OZ2/gGXSMy+4W7UATbUCqfIASxBLF5guZnO7JYYQDIwQ2sgE6mINMZMbfH9/r6uycPY \
MO2LZ5OrSIokKietHKdHTgwBRXFIsAbXWkjIwybno/gwk6cNdcyxsI9FW48lvBi8kgSYbvEr2Qlh \
XURVU/7Q3PFxPW+zwWO0JGj3CjmQk60NZIDYkJ19xtT6IsjGIyhLhvtmjJJ6VJWYKJsxAhligwks \
piCjvQRLkII3Swp2DZwaBISNLdZKeFRTvjdIRQ0re/QFs/ylVMp+5WpwNjiaKNm7CIbdjHipNXRu \
iwtuyphY6/GI47xyNEOg/LQXQ4ui3oGacDPau91oAHiIweEwe3MrFEQWZhw7qj5AKLUxFm6MAy78 \
N7Rr7XWvQ3/Ynt+adz4KIvswH2Pciu27tbFEoVB5iNHGuM558JUlEw/lnMABY1Yd7UEB3hhHP0nm \
0zp4vt54hx6YDDDrPaCVpLXGE+okhHOJv1ZjNrlg1q+j89OjCWKPD+IRG7xh1okuCFITqsxFYchX \
iUaePSAYm3zN36Zv7FR5GO5G0Y3d8nfT8ds8eF7qHfGEv0+MsUs3aTH8JNwhxgig4rWRT0VYmNfW \
7YuHSgthpCWlay47ysrJL07+hr1bO6T9p0MLHEmMs9hWv8Luyfk+SFjNFhhZ/c45DzPhyyX+alI8 \
FDi6gVyU7FMh2j9G9n9f5kfb84+kfig33PrxMFW2oV6ugqg3RkZ3QLFZYdH79jXjoww9ahsi9fHO \
zn0/Gy6t1ZXvqfNh1Yl+hy8xcUwJ680fBJsnQm6bA6a/4507a/OAmzeekATyN6m+TPlGxO59MxW1 \
nuaGVFlneCL9p2eMIyzu/1/vGGb0g3gH8kaXUVnO6PFXU5f/KmY/He6uln7BYZcYIJdXWdP+9fgY \
5/pw8C6dFDZYxiP3/p/KGys2Nm+aP8ozE8Sv9O76zPbddyrW6OJ9Wp810Ab33uPOVNaZ1VYo3p2Y \
qdbw90p6y+rXPfj3032hu5tuN6gTdHtdGmVlhjnXre1TkxG/GafxEClJLE5Kj0qDCdL6OxNV0mls \
5u7AvQpviuxum/RjEa38/X/clksb57ez3zp1iqASLYP/lYDdCioHM29m2AEpN0yHw9EKqPJbg9UJ \
Xo9X5Yig9yjpSHdd0O4MDja3elANBVWr86cgsAXaXxgw0/iZB/RuZto766qq1OwtweoNzN1G/aGc \
Iv11WiyoArIRSckXnT2KorA66ENhN0/iWA3R7Kn9T7WdxpJGFuDTqVAdcBJSy1diTdBphcT63Q1k \
nC3BoWtdVVkDBW7ri4FfS0sm3XANhVqrNlbgc6VUzXAegq2QH+twXQVXo/T/1jmP9uXaV1rtYPAk \
orfqOuUhxe/tMYF4u6fwMWOlVMVwHoKs2jRv//AfWXdcu2wU7WX6irfrwyQAH19swbBOiqmlUGkK \
6qW2DzvwOd4lq7CDbpKtEuqduDenvmtQC6qmZJmP8Q/frqKuVW8tuD4oDBawI2A/1GXVWzygDOMQ \
S3D9BVNasM4B3ScesAu0r2I5vXAJXUq38M8VYAuqpmlQH0k8d2o6suw/8AApENfUWguHwAAAAASU \
VORK5CYII=";
