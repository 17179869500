import React, {useEffect, useState} from "react";
import { useAuth } from "../components/AuthContext";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
  const { oktaAuth } = useOktaAuth();
  const { logout, isAuthenticated } = useAuth();
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
      if(!isAuthenticated) {
        navigate("/");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [count]);

  if(isAuthenticated) {
    logout()
    oktaAuth.signOut();
    localStorage.removeItem('okta-original-uri-storage')
    localStorage.removeItem('okta-cache-storage')
    localStorage.removeItem('okta-shared-transaction-storage')
    localStorage.removeItem('okta-token-storage')
  }
  
  return (
    <div id="home">
      <div>
        {isAuthenticated && (
          <div className="text-red-600">
            <p id="welcome">
              Logged Out
            </p>
          </div>
        )}

      </div>
    </div>
  );
};