import { useEffect, useMemo } from "react";
import {
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  Alert,
} from "@mui/material";
import { modalStyles } from "./styles";
import { useForm } from "react-hook-form";
import { DropzoneArea } from "react-mui-dropzone";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";

export default function UploadModal({ ...rest }) {
  const {
    register,
    reset,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return rest.application;
    }, [rest]),
  });

  useEffect(() => {
    reset(rest.application);
  }, [rest.application]);

  const onFormSubmit = ({
    application_name,
    application_owner,
    application_description,
    application_url,
    documentation_url,
    application_logo_filename,
    application_logo_upload,
    application_roles,
  }) => {
    const submitData = {
      application_name: application_name,
      application_owner: application_owner,
      application_description: application_description,
      application_url: application_url,
      documentation_url: documentation_url,
      application_logo_filename: application_logo_filename,
      application_logo_upload: application_logo_upload,
      application_roles: application_roles,
    };
    rest.onClose();
    rest.saveData(submitData);
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={rest.open}
        onClose={rest.onClose}
      >
        <Box sx={modalStyles.wrapper}>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              "& .MuiTextField-root": { m: 1, width: 400 },
              border: "2px solid black",
              p: 3,
            }}
          >
            <Typography>USCP Application Form</Typography>

            <TextField
              {...register("application_name", { required: true })}
              required
              label="Application Name"
              variant="outlined"
              error={!!errors.application_name}
            />
            {errors.application_name && (
              <Alert severity="error">This field is required</Alert>
            )}

            <TextField
              {...register("application_owner", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
              })}
              required
              label="Application Owner"
              type="email"
              variant="outlined"
              error={!!errors.application_owner}
            />
            {errors.application_owner &&
              errors.application_owner.type === "required" && (
                <Alert severity="error">This field is required.</Alert>
              )}
            {errors.application_owner &&
              errors.application_owner.type === "pattern" && (
                <Alert severity="error">Owner email is not valid.</Alert>
              )}

            <TextField
              {...register("application_url", {
                required: true,
                pattern:
                  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i,
              })}
              required
              label="Application URL"
              type="url"
              variant="outlined"
              error={!!errors.application_url}
            />
            {errors.application_url &&
              errors.application_url.type === "required" && (
                <Alert severity="error">This field is required.</Alert>
              )}
            {errors.application_url &&
              errors.application_url.type === "pattern" && (
                <Alert severity="error">Application URL is not valid.</Alert>
              )}

            <TextField
              {...register("documentation_url", {
                required: false,
                pattern:
                  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i,
              })}
              required
              label="Documentation URL"
              type="url"
              variant="outlined"
              error={!!errors.documentation_url}
            />
            {errors.documentation_url &&
              errors.documentation_url.type === "required" && (
                <Alert severity="error">This field is required.</Alert>
              )}
            {errors.documentation_url &&
              errors.documentation_url.type === "pattern" && (
                <Alert severity="error">Documentation URL is not valid.</Alert>
              )}

            <TextField
              {...register("application_roles", {
                required: false,
              })}
              required
              label="Application Roles"
              variant="outlined"
              error={!!errors.documentation_url}
            />
            {errors.application_roles &&
              errors.application_roles.type === "required" && (
                <Alert severity="error">This field is required.</Alert>
              )}

            <TextField
              {...register("application_description", {
                required: false,
              })}
              required
              multiline
              rows={4}
              label="Application Description"
              variant="outlined"
              error={!!errors.application_description_url}
            />
            {errors.application_description &&
              errors.application_description.type === "required" && (
                <Alert severity="error">This field is required.</Alert>
              )}

            <DropzoneArea
              acceptedFiles={["image/*"]}
              filesLimit={1}
              showAlerts={["error"]}
              dropzoneText={
                "Drag and drop application image here or click to browse"
              }
              showFileNamesInPreview={true}
              showFileNames={true}
              maxFileSize={3000000}
              onChange={(event) => {
                if (event.length > 0) {
                  setValue("application_logo_upload", event[0]);
                } else {
                  setValue("application_logo_upload", undefined);
                }
              }}
            />
            <div className="flex p-3">
              <Button
                variant="outlined"
                sx={hoverStyles}
                onClick={handleSubmit(onFormSubmit)}
              >
                Submit
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
