import { Typography } from "@material-tailwind/react";
import Box from "@mui/material/Box";
import AdminApplications from "../components/AdminApplications.jsx";
import { useAuth } from "../components/AuthContext.jsx";
import React from "react";

export const AdminPage: React.FC = () => {
  const { ...rest } = useAuth();
  return (
    <React.Fragment>
        {rest.isAdmin ? (
          <Box sx={{ width: "100%" }}>
            <AdminApplications />
          </Box>
        ) : (
          <>
          <Typography>
            Access Denied. You do not have permission to access this page.
          </Typography>
          </>
        )}
    </React.Fragment>
  );
};
