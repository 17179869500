import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useAuth } from "./AuthContext";
import { appRoles } from "../authConfig";

export const OktaAutoLogin = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const { login } = useAuth();
  useEffect(() => {
    if (authState !== null && authState.isAuthenticated) {
      oktaAuth
        .getUser()
        .then((info) => {
          console.log(info);
        })
        .catch((err) => {
          console.error(err);
          return;
        });
      const idToken = oktaAuth.getIdToken();
      const accessToken = oktaAuth.getAccessToken();
      const user = authState.accessToken.claims.sub;
      const groups = authState.idToken.claims.Groups;
      let admin = false;
      groups.forEach((element) => {
        if (element === appRoles.Admins) {
          admin = true;
        }
      });
      login(user, groups, accessToken, idToken, admin);
    }
  }, [authState, oktaAuth]); // Update if authState changes

  return <React.Fragment></React.Fragment>;
};
