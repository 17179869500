import React, { useState, useEffect } from "react";
import { useAuth } from "../components/AuthContext";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const { isAuthenticated } = useAuth();
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
      if(isAuthenticated) {
        navigate("/");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [count]);

  const login = async () => {
    oktaAuth.signInWithRedirect({ originalUri: "/" });
  };

  if (authState !== null && !authState.isAuthenticated) {
    console.log("login called");
    login();
  }

  return <></>;
};
