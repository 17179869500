import React from "react";
import { useAuth } from "../components/AuthContext.jsx";
import { Navigate } from "react-router-dom";
import { Applications } from "../components/Applications.jsx";

export const Home = () => {
  const { accessToken, isAuthenticated } = useAuth();
  return (
    <div id="home">
      <div>
        {isAuthenticated ? (
          <div className="text-black">
        <Applications />
          </div>
        ) : <Navigate to="/login"/>} 

      </div>
    </div>
  );
};
