import styled from "@emotion/styled";

export const modalStyles = {
  wrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  },
  inputFields: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    ".MuiInput-root": {
      marginBottom: "10px",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "end",
  },
};

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const ApplicationLogoContainer = styled.div`
  width: 300px;
`;

export const Label = styled.div`
  padding-bottom: 8px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: rgba(0, 0, 0, 0.87);
`;