export const postFile = (endpoint, accessToken, file) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "POST",
    headers: headers,
    body: file,
  };

  fetch(endpoint, options)
};
