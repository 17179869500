import React, { useEffect, useState } from "react";
import BasicSnackbar from "./BasicSnackbar";
import Box from "@mui/material/Box";
import executeFetch from "../helpers/executeFetch";
import tokenValid from "../helpers/tokenValid";
import ApplicationCards from "./ApplicationCards";
import { useAuth } from "./AuthContext";

export const Applications = () => {
  const { ...rest } = useAuth();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const [applicationData, setApplicationData] = useState({
    data: [],
    isLoading: true,
  });

  const [isLoading, setIsLoading] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  const applicationsUrl =
    process.env.REACT_APP_BACKEND_URI + "/api/applications";

  useEffect(() => {
    if (!tokenValid(rest.accessToken)) {
      rest.updateToken();
      return;
    }
    if (rest.isAuthenticated && tokenValid(rest.accessToken)) {
      console.log(rest.accessToken)
      executeFetch("GET", applicationsUrl, rest.accessToken).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          }
          if (!response.isLoading) {
            let sasData = response.data;
            sasData.forEach((element) => {
              if (element.application_logo_filename !== "None") {
                const applicationSasLogoUrl =
                  process.env.REACT_APP_BACKEND_URI +
                  "/api/get_signed_url?file_name=" +
                  element.application_logo_filename;
                executeFetch("GET", applicationSasLogoUrl, rest.accessToken).then(
                  (response) => {
                    element.application_logo_sas_url = response.data.url;
                  }
                );
              } else {
                element.application_logo_sas_url = "";
              }
            });
            setApplicationData((prevState) => ({
              ...prevState,
              data: sasData,
              isLoading: false,
            }));
            setIsLoading(false);
          }
        }
      });
    }
  }, [applicationData.isLoading, rest.isAuthenticated]);

  useEffect(() => {}, [rest.accessToken]);

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{ flexGrow: 1, mx: "auto" }}
      >
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <ApplicationCards
          data={applicationData.data}
          isLoading={isLoading}
        />
      </Box>
    </React.Fragment>
  );
};
