import React from "react";
import { useTheme, Typography } from "@mui/material";

export const StickyFooter = () => {
  const theme = useTheme();

  return (
    <div>
      <Typography variant="body2" color="red" align="right">
        Version {process.env.REACT_APP_VERSION}
      </Typography>
    </div>
  );
};
