import { Avatar, Typography, Popover } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { LogoutButton } from "./LogoutButton";
import { useAuth } from "./AuthContext";

export const UserPopupState = () => {
  const { user, groups } = useAuth();

  const userMatch = user?.match(/^([^@]*)@/);
  const baseName = userMatch ? userMatch[1] : "";
  const userName = baseName.replace(/\./g, " ");

  const letterMatches = userName.match(/\b(\w)/g);
  let initials = "";
  if(letterMatches !== null) {
    initials = letterMatches.join('');
    if (initials === null) {
      initials = "";
    }
  }

  let renderGroups = [];

  if (groups) {
    if (Array.isArray(groups)) {
      groups.forEach((element) => {
        renderGroups = [...renderGroups, element];
      });
    }
  }

  return (
    <PopupState variant="popover" popupId="userid-popup-popover">
      {(popupState) => (
        <div className="flex-row">
          <div>
            <Avatar
              variant="square"
              alt="David"
              sx={{ bgcolor: "#29707A", height: 40, width: 40, m: 2 }}
              {...bindTrigger(popupState)}
            >
              {initials}
            </Avatar>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              slotProps={{
                root: {
                  sx: {
                    overflowY: "scroll",
                    bottom: -2, // Added
                  },
                },
              }}
              disableRestoreFocus
            >
              <div className="flex-col p-4">
                <Typography variant="h6" color="primary">
                  {userName}
                </Typography>
                <Typography>Username: {user}</Typography>
                <div className="flex pb-3">
                  <Typography sx={{ paddingRight: 1 }}>Groups: </Typography>
                  <div>
                    {renderGroups.map((element) => (
                      <Typography>{element}</Typography>
                    ))}
                  </div>
                </div>
                <LogoutButton />
                <br />
              </div>
            </Popover>
          </div>
        </div>
      )}
    </PopupState>
  );
};
