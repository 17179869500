import { useState } from "react";
import { List, ListItem, ListItemPrefix, Card } from "@material-tailwind/react";
import {
  KeyboardDoubleArrowLeft,
  AdminPanelSettings,
  Home,
  Menu,
} from "@mui/icons-material";
import { useAuth } from "./AuthContext.jsx";

export const CollapsableSidebar = () => {
  const [open, setOpen] = useState(true);
  const { ...rest } = useAuth();

  const handleSidebar = () => {
    setOpen(open === true ? false : true);
  };

  return (
    <div className={` flex-col bg-white  ${open ? "w-72" : "w-4"}`}>
      <Card className="h-[calc(100vh-2rem)] w-full max-w-[20rem] shadow-xl">
        <div className={`flex ${open ? "content-end" : "content-start"}`}>
          <a
            role="button"
            onClick={() => handleSidebar()}
          >
            {open ? <KeyboardDoubleArrowLeft /> : <Menu />}
          </a>
        </div>
        {open ? (
          <List>
            <a
              href="/"
              className="text-initial text-lg"
            >
              <ListItem className="hover:bg-primary">
                <ListItemPrefix>
                  <Home className="h-5 w-5" />
                </ListItemPrefix>
                Main Page
              </ListItem>
            </a>
            {rest.isAdmin ? (
              <a
                href="/admin"
                className="text-initial text-lg"
              >
                <ListItem className="hover:bg-primary">
                  <ListItemPrefix>
                    <AdminPanelSettings className="h-5 w-5" />
                  </ListItemPrefix>
                  Admin Page
                </ListItem>
              </a>
            ) : null}
          </List>
        ) : null}
      </Card>
    </div>
  );
};
