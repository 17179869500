import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = (props) => {
  const [user, setUser] = useState(undefined);
  const [groups, setGroups] = useState(undefined);
  const [idToken, setIdToken] = useState(undefined);
  const [accessToken, setAccessToken] = useState(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [refreshToken, setRefreshToken] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const logout = async () => {
    setIdToken(undefined);
    setAccessToken(undefined);
    setUser(undefined);
    setIsAuthenticated(false);
  };

  const login = async (user, groups, accessToken, idToken, admin) => {
    setIdToken(idToken);
    setAccessToken(accessToken);
    setUser(user);
    setIsAuthenticated(true);
    setIsAdmin(admin);
    setGroups(groups);
  };

  const updateToken = async () => {
    setRefreshToken(true);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        groups,
        accessToken,
        idToken,
        isAdmin,
        isAuthenticated,
        refreshToken,
        updateToken,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
