import React from "react";
import { Dialog, Box, DialogTitle, Typography, Button } from "@mui/material";
import { useAuth } from "./AuthContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { useNavigate } from "react-router-dom";

export const SessionDialog = () => {
  const { refreshToken, updateToken } = useAuth();
  const navigate = useNavigate();

  const handleRefresh = () => {
    updateToken();
  };
  const signOutClickHandler = () => {
    navigate("/logout");
  };
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Box display="flex" justifyContent="center">
          <Dialog open={refreshToken}>
            <DialogTitle>{"Login session expired."}</DialogTitle>
            <Box display="flex" justifyContent="center">
              <div className="p-5">
                <div className="flex-col space-y-5">
                  <div className="flex justify-center">
                    <Typography>
                      Please renew your session or sign out.
                    </Typography>
                  </div>
                  <div className="flex space-x-3">
                    <Button
                      variant="outlined"
                      sx={hoverStyles}
                      onClick={() => handleRefresh()}
                    >
                      Renew Session
                    </Button>
                    <Button
                      variant="outlined"
                      sx={hoverStyles}
                      onClick={() => signOutClickHandler()}
                    >
                      Sign out
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
            <br />
          </Dialog>
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
};
