import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { OktaAuth } from "@okta/okta-auth-js";

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const {
  REACT_APP_ISSUER,
  REACT_APP_CLIENT_ID,
  REACT_APP_REDIRECT_URI,
  REACT_APP_REDIRECT_LOGOUT_URI,
  REACT_APP_VERSION
} = process.env;


const oktaAuth = new OktaAuth({
  issuer: REACT_APP_ISSUER,
  clientId: REACT_APP_CLIENT_ID,
  redirectUri: REACT_APP_REDIRECT_URI,
  postLogoutRedirectUri: REACT_APP_REDIRECT_LOGOUT_URI,
  scopes: ["openid"],
  pkce: true,
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <App oktaAuth={oktaAuth} />
    </BrowserRouter>
  </React.StrictMode>
);
