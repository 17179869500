import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicSnackbar from "./BasicSnackbar";
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Typography } from "@mui/material";
import UploadModal from "./UploadModal";
import { postFile } from "../helpers/postFile";
import { renderCellExpand } from "./RenderCellExpand";
import { v4 } from "uuid";
import { useAuth } from "./AuthContext";
import executeFetch from "../helpers/executeFetch";
import tokenValid from "../helpers/tokenValid";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";
import { Tooltip } from "@mui/material";

const userTableStyles = {
  height: "1000px",
};

const backendUrl = process.env.REACT_APP_BACKEND_URI;

const AdminApplicationDataTable = () => {
  const { ...rest } = useAuth();
  const [applicationData, setApplicationData] = useState({
    data: [],
    isLoading: true,
  });

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [refreshData, setRefreshData] = useState(true);
  const [operation, setOperation] = useState("");

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const [application, setApplication] = useState({
    id: 0,
    application_name: "",
    application_owner: "",
    application_description: "",
    application_url: "",
    documentation_url: "",
    application_roles: "",
    application_logo_filename: "none",
    application_logo_upload: undefined,
    application_logo_sas_url: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  const saveData = (data) => {
    let payload = { ...data };

    if (data.application_logo_upload !== undefined) {
      if (application.application_logo_filename !== "None") {
        console.log(data.application_logo_filename);
        console.log("delete old file");
        const deleteApplicationLogoUrl =
          backendUrl +
          "/api/delete_file/" +
          application.application_logo_filename;

        executeFetch("DELETE", deleteApplicationLogoUrl, rest.accessToken)
          .then((response) => {})
          .catch((response) => {});
      }
      const uuid = v4();
      const addApplicationFileUrl = backendUrl + "/api/upload_file";
      const fileData = new FormData();
      fileData.append(
        "file",
        data.application_logo_upload,
        uuid + "-" + data.application_logo_upload.name
      );

      postFile(addApplicationFileUrl, rest.accessToken, fileData);

      payload.application_logo_filename =
        uuid + "-" + data.application_logo_upload.name;
    } else {
      payload.application_logo_filename = application.application_logo_filename;
    }
    switch (operation) {
      case "add":
        const addApplicationUrl = backendUrl + "/api/add_application";
        executeFetch("POST", addApplicationUrl, rest.accessToken, payload).then(
          (response) => {}
        );
        break;
      case "update":
        const updateApplicationUrl =
          backendUrl + "/api/update_application/" + application.id;
        executeFetch(
          "PUT",
          updateApplicationUrl,
          rest.accessToken,
          payload
        ).then((response) => {});
        break;
      default:
        break;
    }

    setRefreshData(true);
  };

  const applicationUrl = backendUrl + "/api/applications";

  useEffect(() => {
    if (!tokenValid(rest.accessToken)) {
      rest.setRefreshToken(true);
      return;
    }
    if (rest.isAuthenticated && tokenValid(rest.accessToken)) {
      executeFetch("GET", applicationUrl, rest.accessToken).then((response) => {
        if (response) {
          if (response.isError) {
            console.log(response.error);
            setSnackbar({
              open: true,
              message: response.error,
              severity: "error",
            });
          }
          if (!response.isLoading) {
            setApplicationData({ data: response.data, isLoading: false });
          }
        }
        setRefreshData(false);
      });
    }
  }, [applicationData.isLoading, refreshData]);

  const columns = [
    {
      field: "edit",
      headerName: "Edit",
      width: 20,
      renderCell: (cellValues) => {
        return (
          <IconButton
            aria-label="edit"
            onClick={(event) => {
              handleRowEditClick(event, cellValues);
            }}
          >
            <EditIcon />
          </IconButton>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: "application_name",
      headerName: "Application Name",
      width: 175,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "application_owner",
      headerName: "Application Owner",
      width: 200,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "application_url",
      headerName: "Application URL",
      width: 200,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "documentation_url",
      headerName: "Documentation URL",
      width: 200,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "application_logo_filename",
      headerName: "Application Logo Filename",
      width: 200,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "application_roles",
      headerName: "Application Roles",
      width: 150,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "application_description",
      headerName: "Application Description",
      width: 350,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: " ",
      headerName: "Delete",
      width: 60,
      renderCell: (cellValues) => {
        return (
          <IconButton
            aria-label="delete"
            onClick={(event) => {
              handleDeleteClick(event, cellValues);
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
      description: "Delete Application",
    },
  ];

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this application?"
    );

    if (isConfirmed) {
      const deleteApplicationUrl =
        backendUrl + "/api/delete_application/" + cellValues.row.id;
      const deleteApplicationLogoUrl =
        backendUrl +
        "/api/delete_file/" +
        cellValues.row.application_logo_filename;

      executeFetch("DELETE", deleteApplicationLogoUrl, rest.token)
        .then((response) => {})
        .catch((response) => {});

      executeFetch("DELETE", deleteApplicationUrl, rest.token)
        .then((response) => {
          setRefreshData(true);
        })
        .catch((response) => {});
    }
  };

  const handleRowEditClick = (event, cellValues) => {
    setApplication({
      id: cellValues.row.id,
      application_name: cellValues.row.application_name,
      application_owner: cellValues.row.application_owner,
      application_description: cellValues.row.application_description,
      application_url: cellValues.row.application_url,
      documentation_url: cellValues.row.documentation_url,
      application_logo_filename: cellValues.row.application_logo_filename,
      application_roles: cellValues.row.application_roles,
      application_logo_upload: undefined,
    });
    setOperation("update");
    setShowUploadModal(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setApplication({
      application_name: "",
      application_owner: "",
      application_description: "",
      application_url: "",
      documentation_url: "",
      application_logo_filename: "",
      application_roles: "",
      application_logo_upload: undefined,
    });
    setOperation("add");
    setShowUploadModal(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div>
          <form>
            <div className="form-group pb-5 pl-3">
              <Tooltip title="Add Application">
                <Button
                  variant="outlined"
                  sx={hoverStyles}
                  className="button bg-primary text-white p-2 ml-1 mb-1 pt-2"
                  onClick={onSubmit}
                >
                  Add Application
                </Button>
              </Tooltip>
            </div>
          </form>
        </div>
        <Typography variant="h6"> Current Applications</Typography>
        <Box component="main" sx={{ flexGrow: 1, mx: "auto" }}>
          <BasicSnackbar
            open={snackbar.open}
            severity={snackbar.severity}
            message={snackbar.message}
            onClose={handleClose}
            vertical="top"
            horizontal="right"
          />
          <DataGrid
            onError={() => setSnackbar(true)}
            rows={applicationData.data}
            rowCount={applicationData.data.length}
            columns={columns}
            loading={applicationData.isLoading}
            sx={userTableStyles}
            disableColumnSelector={true}
            disableMultipleSelection={true}
            hideFooterPagination
            columnVisibilityModel={{
              application_logo_filename: false,
            }}
          />
        </Box>
      </div>
      <UploadModal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        saveData={saveData}
        application={application}
      />
    </ThemeProvider>
  );
};

export default AdminApplicationDataTable;
