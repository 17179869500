/**
 * Execute a fetch request with the given options
 * @param {string} method: GET, POST, PUT, DELETE
 * @param {String} endpoint: The endpoint to call
 * @param {Object} data: The data to send to the endpoint, {optional}
 * @returns JSON response
 */
const executeFetch = async (method, endpoint, token, data = null) => {
  const handleResponse = (response) => {
    return response.json().then((json) => {
      let modifiedJson = null;
      if (response.ok) {
        modifiedJson = {
          data: json,
          isError: false,
        };
      } else {
        modifiedJson = {
          error:
            method +
            " " +
            endpoint +
            " " +
            response.status +
            " (" +
            response.statusText +
            ")",
          data: [],
          isError: true,
        };
      }

      // If request failed, reject and return modified json string as error
      if (!response.ok) return Promise.reject(JSON.stringify(modifiedJson));

      // If successful, continue by returning modified json string
      return JSON.stringify(modifiedJson);
    });
  };

  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);

  if (data) headers.append("Content-Type", "application/json");

  let options = {
    method: method,
    headers: headers,
    body: data ? JSON.stringify(data) : null,
  };

  const promise = new Promise((resolve, reject) => {
    let result = null;
    fetch(endpoint, options)
      .then(handleResponse)
      .then((response) => JSON.parse(response))
      .then((json) => resolve(json))
      .catch((error) => {
        let modifiedJson = null;
        try {
          modifiedJson = JSON.parse(error);
        } catch (e) {
          modifiedJson = {};
          modifiedJson.error = "net:: Connection Failed: " + endpoint;
          modifiedJson.isError = true;
        }
        if (options.method === "GET") {
          modifiedJson.data = [];
        }
        result = modifiedJson;
        return Promise.resolve(JSON.stringify(modifiedJson));
      })
      .then(() => resolve(result));
  });
  return promise;
};

export default executeFetch;
