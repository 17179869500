import { useEffect, useState, useCallback } from "react";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "./AuthContext.jsx";

export const RefreshToken = () => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { accessToken, updateToken } = useAuth();

  useEffect(() => {
    if (accessToken && (accessToken !== undefined)) {
      const decodedTokenExpiry = jwtDecode(accessToken).exp;
      const tokenExpiryTime = decodedTokenExpiry * 1000;
      const fourMinPriorExpiryTime = tokenExpiryTime - 4 * 60000;
      const currentTime = Date.now();
      const intervalTime = fourMinPriorExpiryTime - currentTime;
      const interval = setInterval(() => {
        if (tokenExpiryTime < fourMinPriorExpiryTime) {
          console.log("Token about to expire: ");
          updateToken();
          forceUpdate();
        }
      }, intervalTime);
      return () => clearInterval(interval);
    }
  });
  return (
    <></>
  )
};
