import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  Button,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { Tooltip } from "@mui/material";
import MonitorIcon from "@mui/icons-material/Monitor";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ThemeProvider } from "@mui/material/styles";
import { theme, hoverStyles } from "../styles/theme";

const ApplicationCards = (...rest) => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (rest[0].isLoading) {
    return <div>loading...</div>;
  }

  if (rest[0].data.length === 0) {
    return <div>No applications</div>;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          container
          spacing={4}
          style={{
            paddingTop: "50px",
            paddingLeft: "50px",
            paddingRight: "50px",
          }}
        >
          {rest[0].data.map((element) => (
            <Grid item xs={3} key={element.id}>
              <Card
                variant="outlined"
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardActionArea
                  onClick={() => window.open(element.application_url, "_blank")}
                >
                  <CardHeader title={element.application_name} />
                  {element.application_logo_sas_url !== "" ? (
                    <img
                      alt=""
                      src={element.application_logo_sas_url}
                      style={{
                        width: "250px",
                        height: "200px",
                        margin: "auto",
                      }}
                    />
                  ) : (
                    <MonitorIcon
                      style={{
                        width: "250px",
                        height: "200px",
                        margin: "auto",
                      }}
                    />
                  )}
                </CardActionArea>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Application Description
                  </AccordionSummary>

                  <CardContent>
                    <Typography
                      variant="body2"
                      whiteSpace="pre-line"
                      color="text.secondary"
                    >
                      {element.application_description}
                    </Typography>
                  </CardContent>
                </Accordion>
                <CardContent style={{ marginTop: "auto" }}>
                  <div className="flex p1">
                    <Tooltip title="Application Documentation">
                      <Button
                        variant="outlined"
                        sx={hoverStyles}
                        className="button bg-primary text-white p-2 ml-1 mb-1 pt-2"
                        onClick={() =>
                          window.open(element.documentation_url, "_blank")
                        }
                      >
                        Learn More
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title={
                        "Application Owner: mailto:" + element.application_owner
                      }
                    >
                      <IconButton
                        aria-label="contact application owner"
                        rel="noopener noreferrer"
                        href={"mailto:" + element.application_owner}
                      >
                        <EmailIcon></EmailIcon>
                      </IconButton>
                    </Tooltip>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default ApplicationCards;
